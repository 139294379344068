.delegate-modal-container {
    padding: var(--amplify-space-xl);
    gap: var(--amplify-space-large); 
    flex-direction: column;
}

.delegate-modal-alert-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-break: break-word;
}