.report-card {
  border: 1px solid #EFF0F0;
  border-radius: 8px;
  padding: var(--amplify-space-large);
}

.tab-current-reports-container {
  padding-top: 24px;
  min-height: 260px;
  gap: 24px;
}

.report-heading {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: #326297;
  letter-spacing: -0.02em;
  text-align: left;
}

.report-date-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.report-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.report-date-quarter {
  font-weight: 400;
  font-size: 14.4px;
  line-height: 22px;
  color: #5C6670;
}

.report-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.report-bottom-info {
  position: relative;
}

.report-action-btn {
  margin-left: auto;
  font-weight: normal;
  border-radius: 8px;
  padding: 12px;
}

/* Hide thumb on slider for progress */
.amplify-sliderfield__track + span {
  display: none;
}

.progress-container {
  width: 100%;
}

.report-download-link:hover {
  text-decoration: underline;
}

.progress-text-wrapper {
  border-top: 1px solid #ebeced;
  padding: 8px 0px;
}

.long-text {
  margin-left: -40px;
}

@media screen and (max-width: 992px) {
  .report-card {
    padding: 16px
  }
}
