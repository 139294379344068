.report-progress-capsule-wrapper {
  width: 158px;
  height: 38px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.report-progress-capsule-wrapper:disabled {
  cursor: default;
}

.report-overdue {
  background: #FEF2F2;
}

.report-overdue p {
  color: #CF1919;
}

.report-in-progress {
  background: #F3F7FB;
}

.report-in-progress p {
  color: #326297;
}

.report-not-submitted {
  background: #FFFBEB;
}

.report-not-submitted p {
  color: #B14F22;
}

.report-submitted {
  background: #ECFDF5;
}

.report-submitted p {
  color: #157C4F;
}

.report-not-started {
  background: #FFFFFF;
  border: 1px solid #ebeced;
}

.report-not-started p {
  color: #2C5684;
}