.container-grid {
  margin: 0;
  /* Gap is removed at this width. Adding padding to containers to center content */
  @media (max-width: 448px) {
    padding: 0px var(--amplify-space-large);
  }
}

.dashboard-container-right {
  margin: 0;
  /* Gap is removed at this width. Adding padding to containers to center content */

  @media (max-width: 768px) {
    /*  Gap between the dashboard content and the edge of the screen is 16px in total. 
        Remaining gap distance is controlled by the grid column width.
    */
    padding: 0px 4px;
  }
  @media (max-width: 448px) {
    padding-top: var(--amplify-space-large);
  }
}
