.manual-authorise-container {
  margin: 0;
}

.breadcrumb {
  margin-top: 16px;
}

.alert-component-error.alert-component {
  gap: 4px;
}

.manual-authorise-heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0d1926;
}

.manual-auth-text {
  color: #0d1926;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.manual-authorise-input input {
  min-height: 46px;
  width: 367px;
  border-radius: 8px;
  border-color: #878889;
}

.manual-authorise-submit-btn {
  min-width: 174px;
  min-height: 46px;
  border-radius: 8px;
  background-color: #326297;
}

@media (max-width: 768px) {
  .manual-authorise-input input {
    width: 100%;
  }

  .manual-authorise-submit-btn {
    width: 100%;
  }
}

@media (max-width: 448px) {
  .manual-authorise-container {
    padding: 0px var(--amplify-space-medium);
  }
}
