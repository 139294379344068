.add-account-modal-content {
  position: fixed;
	background-color: white;
  top: 6rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 768px;
  min-width: 320px;
  max-height: calc(100vh - 8rem);
  border: solid 1px #ebeced;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
	padding-top: 20px;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;

	@media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
    top: 1rem;
    max-height: calc(100vh - 2rem);
  }

  .add-modal-tbody {
    max-height: calc(100vh - 33rem);
    
    @media (max-width: 768px) {
      max-height: calc(100vh - 24rem);
    }
  }
}

.add-account-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(75, 79, 84, 0.17);
  z-index: 10000;
}

.button-box {
  justify-content: left;
}