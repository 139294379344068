.authorise-btn {
  border: none;
  font-weight: normal;
  padding-left: 0;

  --amplify-components-button-hover-background-color: #a6d8ff;

  /* positioning context for spinner */
  position: relative;

  /* resize spinner */
  --amplify-components-loader-width: 32px;
  --amplify-components-loader-height: 32px;

  /* maintain same gap as default button state */
  --amplify-components-button-loader-wrapper-gap: 0;

  /* avoid text colour change */
  --amplify-components-button-loading-color: var(
    --amplify-components-button-color
  );
}

/* override position of loading spinner on button (amplify does not provide positioning customization) */
.authorise-btn > .amplify-button__loader-wrapper > .amplify-loader {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.authorisation-inline-error {
  margin-top: 16px;
  font-size: 14px;
  line-height: 16px;
  padding-left: 20px;
}

.dsp-card {
  grid-template-columns: 1fr;

  &-disabled {
    --amplify-components-text-color: var(
      --amplify-colors-font-disabled,
      #89949f
    );

    // grey out the icons using a filter
    svg {
      // Changes all colours to #89949f, requires detail to be transparent otherwise it will become grey too
      filter: brightness(0) saturate(100%) invert(63%) sepia(24%) saturate(178%)
        hue-rotate(169deg) brightness(89%) contrast(85%);
    }
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    grid-template-columns: 2fr;
  }

  @media (max-width: 767px) {
    max-width: 767px;
  }

  @media (max-width: 480px) {
    max-width: 480px;
  }
}

.authorisation-form {
  display: grid;
  max-height: 72px;
  max-width: 100%;
}
