.submit-modal-heading {
  font-size: 1.5rem;
  font-weight: 600;
}

.submit-modal-text {
  color: var(--amplify-colors-font-primary, #0D1926);
  font-family: "Open Sans";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.modal-button-group {
  width: 100%;
  justify-content: start;

  button {
    min-width: 174px;
    min-height: 46px;
    border-radius: 8px;
  }
}

.modal-icon-container {
  height: 36px;
}

.submit-modal-error {
  font-size: 14px;
}

.missing-info-warnings {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--Warning-50, #FFFBEB);
  border-radius: 8px;
  padding: 0 1rem;

  p {
    color: #78350F;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    
  }

  .edit-text {
    font-weight: 400;
    text-decoration: underline;
    text-decoration-color: #78350F;
  }

  svg {
    path {
      fill: #78350F;
    }
  }
}

.missing-info-row {
  display: flex;
  padding: 0.75rem 0;
  justify-content: space-between;
  border-bottom: 1px solid var(--Warning-100, #FEF3C7);
}

.missing-info-row:last-child {
  border-bottom: none;
}

//-----------Loading animation css:
$dot-color: #326297;
$dot-size: 8px;
$dot-animation-speed: 0.7s;
$dot-animation: dot-flashing $dot-animation-speed infinite linear alternate;
$dot-spacing: 15px;

.dot-flashing {
  position: relative;
  width: $dot-size;
  height: $dot-size;
  border-radius: 5px;
  background-color: $dot-color;
  color: $dot-color;
  animation: $dot-animation;
  animation-delay: calc($dot-animation-speed / 2);
  margin-top: 14px;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: calc($dot-spacing * -1);
  width: $dot-size;
  height: $dot-size;
  border-radius: 5px;
  background-color: $dot-color;
  color: $dot-color;
  animation: $dot-animation;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: $dot-spacing;
  width: $dot-size;
  height: $dot-size;
  border-radius: 5px;
  background-color: $dot-color;
  color: $dot-color;
  animation: $dot-animation;
  animation-delay: $dot-animation-speed;
}

@keyframes dot-flashing {
  0% {
    background-color: $dot-color;
  }
  50%, 100% {
    background-color: #DCDDDF;
  }
}

@media all and (max-width: 768px) {
  .submit-modal-content {
    padding: 1rem;
    gap: 1rem;
  }

  .modal-button-group {
    flex-direction: column;
  }
}

@media only screen and (min-width: 769px) {
  .submit-modal-content {
    max-width: 46rem;
    padding: 2rem;
    gap: 2rem;
  }
}